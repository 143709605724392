import getCache from 'cache/cache';
import isLoggedIn from 'utils/isLoggedIn';
import { deleteCookie } from 'cookies-next';
import type { GetServerSideProps } from 'next';
import type { NextPage } from 'next';
import type { ShowroomAppProps } from './_app';
import type { Content } from 'types/Content.interface';

import React, { useState, useEffect } from 'react';

import Layout from 'layouts/Layout';
import TemplateFactory from 'templates/TemplateFactory';
import GeneralError from "components/GeneralError";
import FloatingNewsLetter from 'components/FloatingNewsletter';
import LeadPopups from 'components/LeadPopups';

export const getServerSideProps: GetServerSideProps = async ({ req, res }) => {
  try {
    const isAuthenticated = isLoggedIn(req, res);
    const { data, xCacheNext } = await getCache<Content>(
      `${process.env.API_URI}/api/v1/pages/`,
      isAuthenticated,
      req.headers.cookie as string
    );
    res.setHeader('x-cache-next', xCacheNext);
    return {
      props: data,
    };
  } catch (error: any) {
    if (error?.status === 401 || error?.status === 403) {
      deleteCookie('showroom_backend_session_' + process.env.RELEASE_STAGE, { req, res, domain: process.env.API_COOKIE_DOMAIN ?? '.showroom.shopping' });
      return {
        props: {},
        redirect: {
          destination: '/login',
          permanent: false,
        }
      }
    } else {
      return { props: {} }
    }
  }
}

interface PageProps extends ShowroomAppProps, Content { }

const Home: NextPage<PageProps> = ({ page, isAuthenticated }) => {
  //console.log(page);

  const [showForm, setShowForm] = useState(false)
  const [formVariant, setFormVariant] = useState('')

  useEffect(() => {
    window.addEventListener("showCreatorsContactForm", (e) => {
      setFormVariant('creator');
      setShowForm(true);
    }, false);
    window.addEventListener("showBrandsContactForm", (e) => {
      setFormVariant('brand');
      setShowForm(true);
    }, false);
  }, [])


  return (
    !page ?
      <Layout title="Ajdå :*(" isAuthenticated={isAuthenticated} meta={[]}>
        <GeneralError />
      </Layout> :
      <>
        <Layout title={page?.title} meta={page?.meta} isAuthenticated={isAuthenticated}>
        <TemplateFactory sections={page?.sections} />
          {/* <FloatingNewsLetter /> */}
        </Layout>
        <LeadPopups />
      </>
  );
};

export default Home;
