const GeneralError = () => {
  return (
    <div className="container flex flex-col justify-center text-center">
      <h1 className="heading-h1 mt-20 mb-10">Fem oh oh...</h1>
      <p className="heading-h3 mb-10">Här är det inte meningen att du ska hamna, så något gick riktigt snett.</p>
      <p className="mb-20">Hör av dig till oss: <br />tech@showroom.shopping<br /> och berätta om vad som hänt.</p>
    </div>
  )
}

export default GeneralError
